import { defineStore } from "pinia";

export const useMembershipDrawerStore = defineStore("useMembershipDrawerStore", () => {
  const isVisible = ref(false);
  const price = ref<number>();
  const discount = ref<number>();
  const department = ref<string>();

  function open(currentPrice?: number, currentDiscount?: number, currentDepartment?: string) {
    isVisible.value = true;

    price.value = currentPrice;
    discount.value = currentDiscount;
    department.value = currentDepartment;
  }

  function close() {
    isVisible.value = false;
    // To not have the drawer flickering when closing as the box would get removed
    setTimeout(() => {
      price.value = undefined;
      discount.value = undefined;
    }, 500);
  }

  return { close, department, discount, isVisible, open, price };
});
